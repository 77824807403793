import React from 'react';
import { Navigate } from 'react-router-dom';

export function ProtectedRoute({ children, roleRequired }) {
  // Verificamos si el usuario está autenticado
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  // Obtenemos el rol desde localStorage
  const userRole = localStorage.getItem('position');

  // Si no está autenticado, lo mandamos a login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Si se exige un rol y el usuario no lo cumple, también va a login
  if (roleRequired && userRole !== roleRequired) {
    return <Navigate to="/login" replace />;
  }

  // Si pasa ambas verificaciones, renderizamos la ruta protegida
  return children;
}
