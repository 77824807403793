// React Puro
import React, { useState, useEffect } from "react";


// Material Mui
import {
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  MenuItem,
  Snackbar,
  Alert,
  Autocomplete
} from "@mui/material";

import { grey } from '@mui/material/colors';

/**
 * CSS
 */

const AgregarTarifaFINProveedor = ({ open, setOpen, folio, Idd }) => {

  useEffect(() => {
    if (
      folio &&
      (folio.id.includes("CHILP") ||
        folio.id.includes("DPSCL") ||
        folio.id.includes("PISCL"))
    ) {
      setId(folio.id);
      setNRT(folio.id);
      setTerminal(folio.Datos.terminal || "");
      setNSolicitud(folio.Datos.nSolicitud || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setComuna(folio.Datos.comuna || "");
      setCounter(folio.Datos.counter || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setPAX(folio.Datos.pax || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setTarifa(folio.Datos.tarifa || "");
      setTotal(folio.Datos.total || "");
      setTotalTyt(folio.Datos.totaltyt || "");
      if (folio && folio.Viaje) {
        const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === idd);
        if (viajeEncontrado) {
          setIdd(viajeEncontrado.idd);
          setDestino(viajeEncontrado.Destino);
          setOrigen(viajeEncontrado.Origen);
          setHora(viajeEncontrado.Hora);
          setFecha(viajeEncontrado.Fecha);
          setPAXR(viajeEncontrado.PaxR || "");
          setVehiculoExtra(viajeEncontrado.VehiculoExtra);
          setVehiculoNoShow(viajeEncontrado.VehiculoNoShow);
          setEstado(viajeEncontrado.Estado);
          setPAXNombre(viajeEncontrado.paxNombre || "");
          setValidacionProveedor(viajeEncontrado.validacionProveedor || "");

          if (viajeEncontrado.DatosProveedor) {
            setVehiculoExtraProvee(
              viajeEncontrado.DatosProveedor.VehiculoExtra || ""
            );
            setComentarioExtraProvee(
              viajeEncontrado.DatosProveedor.ComentarioExtra || ""
            );
            setStatusExtra(viajeEncontrado.DatosProveedor.StatusExtra || "");

            setVehiculoNoShowProvee(
              viajeEncontrado.DatosProveedor.VehiculoNoShow || ""
            );
            setComentarioNoShowProvee(
              viajeEncontrado.DatosProveedor.ComentarioNoShow || ""
            );
            setStatusNoShow(viajeEncontrado.DatosProveedor.StatusNoShow || "");

            setNombrePax(viajeEncontrado.DatosProveedor.NombrePax || "");
            setPaxTrasladadosProvee(
              viajeEncontrado.DatosProveedor.PaxTrasladados || ""
            );
            setStatusPax(viajeEncontrado.DatosProveedor.StatusPax || "");

            setTipoDeVehiculoo(
              viajeEncontrado.DatosProveedor.TipoDeVehiculo || ""
            );
            setStatusVehiculo(
              viajeEncontrado.DatosProveedor.StatusVehiculo || ""
            );
          }
        } else {
        }
      }
      setCliente(folio.DatosCliente.cliente || "");
      setTelefono(folio.DatosCliente.telefono || "");
      setCorreo(folio.DatosCliente.correo || "");
      setComentario(folio.DatosCliente.comentario || "");

      setStatusFINCobro(folio.Finanzas?.statusFINCobro || "");
      setStatusFINPago(folio.Finanzas?.statusFINPago || "");
      setVehiculosFINProveedor(folio.Finanzas?.vehiculosFINProveedor || "");
      setTarifaIFINProveedor(folio.Finanzas?.tarifaIFINProveedor || "");
      setComentarioFINProveedor(folio.Finanzas?.comentarioFINProveedor || "");

    }
    if (folio && folio.id.includes("SPA")) {
      setId(folio.id);
      setNRT(folio.id);
      setNSolicitud(folio.Datos.nSolicitud || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setComuna(folio.Datos.comuna || "");
      setCounter(folio.Datos.counter || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setPAX(folio.Datos.pax || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setTarifa(folio.Datos.tarifa || "");
      setTotal(folio.Datos.total || "");
      setTotalTyt(folio.Datos.setTotalTyt || "");
      if (folio && folio.Viaje) {
        const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === idd);
        if (viajeEncontrado) {
          setIdd(viajeEncontrado.idd);
          setDestino(viajeEncontrado.Destino);
          setOrigen(viajeEncontrado.Origen);
          setHora(viajeEncontrado.Hora);
          setFecha(viajeEncontrado.Fecha);
          setPAXR(viajeEncontrado.PaxR || "");
          setVehiculoExtra(viajeEncontrado.VehiculoExtra);
          setVehiculoNoShow(viajeEncontrado.VehiculoNoShow);
          setEstado(viajeEncontrado.Estado);
          setPAXNombre(viajeEncontrado.paxNombre || "");
          setValidacionProveedor(viajeEncontrado.validacionProveedor || "");

          if (viajeEncontrado.DatosProveedor) {
            setVehiculoExtraProvee(
              viajeEncontrado.DatosProveedor.VehiculoExtra || ""
            );
            setComentarioExtraProvee(
              viajeEncontrado.DatosProveedor.ComentarioExtra || ""
            );
            setStatusExtra(viajeEncontrado.DatosProveedor.StatusExtra || "");

            setVehiculoNoShowProvee(
              viajeEncontrado.DatosProveedor.VehiculoNoShow || ""
            );
            setComentarioNoShowProvee(
              viajeEncontrado.DatosProveedor.ComentarioNoShow || ""
            );
            setStatusNoShow(viajeEncontrado.DatosProveedor.StatusNoShow || "");

            setNombrePax(viajeEncontrado.DatosProveedor.NombrePax || "");
            setPaxTrasladadosProvee(
              viajeEncontrado.DatosProveedor.PaxTrasladados || ""
            );
            setStatusPax(viajeEncontrado.DatosProveedor.StatusPax || "");

            setTipoDeVehiculoo(
              viajeEncontrado.DatosProveedor.TipoDeVehiculo || ""
            );
            setStatusVehiculo(
              viajeEncontrado.DatosProveedor.StatusVehiculo || ""
            );
          }
        } else {
        }
      }
      setCliente(folio.DatosCliente.cliente || "");
      setTelefono(folio.DatosCliente.telefono || "");
      setCorreo(folio.DatosCliente.correo || "");
      setComentario(folio.DatosCliente.comentario || "");
    }
    if (folio && folio.id.includes("SE")) {
      setId(folio.id || "");
      setNRT(folio.id || "");
      setPais(folio.Datos.pais || "");
      setTerminal(folio.Datos.terminal || "");
      setAeropuerto(folio.Datos.aeropuerto || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setCDistritos(folio.Datos.cDistritos || "");
      setNVoucher(folio.Datos.nVoucher || "");
      setPAX(folio.Datos.pax || "");
      setTipoDeVehiculo(folio.Datos.tipoDeVehiculo || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setTotal(folio.Datos.total || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setCounter(folio.Datos.counter || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setValorVoucher(folio.Datos.valorVoucher || "");
      setTotalTyt(folio.Datos.totaltyt || "");
      if (folio && folio.Viaje) {
        const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === idd);
        if (viajeEncontrado) {
          setIdd(viajeEncontrado.idd);
          setDestino(viajeEncontrado.Destino);
          setOrigen(viajeEncontrado.Origen);
          setHora(viajeEncontrado.Hora);
          setFecha(viajeEncontrado.Fecha);
          setPAXR(viajeEncontrado.PaxR || "");
          setVehiculoExtra(viajeEncontrado.VehiculoExtra);
          setVehiculoNoShow(viajeEncontrado.VehiculoNoShow);
          setEstado(viajeEncontrado.Estado);
          setPAXNombre(viajeEncontrado.paxNombre || "");
          setValidacionProveedor(viajeEncontrado.validacionProveedor || "");
          console.log("viajeEncontrado", viajeEncontrado);
          if (viajeEncontrado.DatosProveedor) {
            setVehiculoExtraProvee(
              viajeEncontrado.DatosProveedor.VehiculoExtra || ""
            );
            setComentarioExtraProvee(
              viajeEncontrado.DatosProveedor.ComentarioExtra || ""
            );
            setStatusExtra(viajeEncontrado.DatosProveedor.StatusExtra || "");

            setVehiculoNoShowProvee(
              viajeEncontrado.DatosProveedor.VehiculoNoShow || ""
            );
            setComentarioNoShowProvee(
              viajeEncontrado.DatosProveedor.ComentarioNoShow || ""
            );
            setStatusNoShow(viajeEncontrado.DatosProveedor.StatusNoShow || "");

            setNombrePax(viajeEncontrado.DatosProveedor.NombrePax || "");
            setPaxTrasladadosProvee(
              viajeEncontrado.DatosProveedor.PaxTrasladados || ""
            );
            setStatusPax(viajeEncontrado.DatosProveedor.StatusPax || "");

            setTipoDeVehiculoo(
              viajeEncontrado.DatosProveedor.TipoDeVehiculo || ""
            );
            setStatusVehiculo(
              viajeEncontrado.DatosProveedor.StatusVehiculo || ""
            );
          }
        } else {
        }
      }
      setTipoDeVehiculoPro(folio.DatosProveedor.tipoDeVehiculoPro || "");
      setNVehiculosPro(folio.DatosProveedor.nVehiculosPro || "");
      setTotalProvee(folio.DatosProveedor.totalProvee || "");
      setComentario(folio.DatosProveedor.comentario || "");

      setStatusFINCobro(folio.Finanzas?.statusFINCobro || "");
      setStatusFINPago(folio.Finanzas?.statusFINPago || "");
      setVehiculosFINProveedor(folio.Finanzas?.vehiculosFINProveedor || "");
      setTarifaIFINProveedor(folio.Finanzas?.tarifaIFINProveedor || "");
      setComentarioFINProveedor(folio.Finanzas?.comentarioFINProveedor || "");
    }
    if (folio && folio.id.includes("KPS")) {
      setId(folio.id || "");
      setNRT(folio.id || "");
      setNSolicitud(folio.Datos.nSolicitud || "");
      setNVoucher(folio.Datos.nVoucher || "");
      setPAX(folio.Datos.pax || "");
      setTotal(folio.Datos.total || "");
      setCounter(folio.Datos.counter || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setValorVoucher(folio.Datos.valorVoucher || "");
      setTotal(folio.Datos.total || "");
      setTotalTyt(folio.Datos.totaltyt || "");
      if (folio && folio.Viaje) {
        const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === idd);
        if (viajeEncontrado) {
          setIdd(viajeEncontrado.idd);
          setDestino(viajeEncontrado.Destino);
          setOrigen(viajeEncontrado.Origen);
          setHora(viajeEncontrado.Hora);
          setFecha(viajeEncontrado.Fecha);
          setPAXR(viajeEncontrado.PaxR || "");
          setVehiculoExtra(viajeEncontrado.VehiculoExtra);
          setVehiculoNoShow(viajeEncontrado.VehiculoNoShow);
          setEstado(viajeEncontrado.Estado);
          setPAXNombre(viajeEncontrado.paxNombre || "");
          setValidacionProveedor(viajeEncontrado.validacionProveedor || "");

          if (viajeEncontrado.DatosProveedor) {
            setVehiculoExtraProvee(
              viajeEncontrado.DatosProveedor.VehiculoExtra || ""
            );
            setComentarioExtraProvee(
              viajeEncontrado.DatosProveedor.ComentarioExtra || ""
            );
            setStatusExtra(viajeEncontrado.DatosProveedor.StatusExtra || "");

            setVehiculoNoShowProvee(
              viajeEncontrado.DatosProveedor.VehiculoNoShow || ""
            );
            setComentarioNoShowProvee(
              viajeEncontrado.DatosProveedor.ComentarioNoShow || ""
            );
            setStatusNoShow(viajeEncontrado.DatosProveedor.StatusNoShow || "");

            setNombrePax(viajeEncontrado.DatosProveedor.NombrePax || "");
            setPaxTrasladadosProvee(
              viajeEncontrado.DatosProveedor.PaxTrasladados || ""
            );
            setStatusPax(viajeEncontrado.DatosProveedor.StatusPax || "");

            setTipoDeVehiculoo(
              viajeEncontrado.DatosProveedor.TipoDeVehiculo || ""
            );
            setStatusVehiculo(
              viajeEncontrado.DatosProveedor.StatusVehiculo || ""
            );
          }
        } else {
        }
      }
      setCliente(folio.DatosCliente.cliente || "");
      setTelefono(folio.DatosCliente.telefono || "");
      setCorreo(folio.DatosCliente.correo || "");
      setComentario(folio.DatosCliente.comentario || "");

      setStatusFINCobro(folio.Finanzas?.statusFINCobro || "");
      setStatusFINPago(folio.Finanzas?.statusFINPago || "");
      setVehiculosFINProveedor(folio.Finanzas?.vehiculosFINProveedor || "");
      setTarifaIFINProveedor(folio.Finanzas?.tarifaIFINProveedor || "");
      setComentarioFINProveedor(folio.Finanzas?.comentarioFINProveedor || "");
    }
  }, [folio, Idd]);

  // Detalle general Despegar
  const [id, setId] = useState("");
  const [terminal, setTerminal] = useState("");
  const [nrt, setNRT] = useState([]);
  const [nSolicitud, setNSolicitud] = useState("");
  const [comuna, setComuna] = useState(null);
  const [counter, setCounter] = useState("");
  const [horaVuelo, setHoraVuelo] = useState("");
  const [aerolinea, setAerolinea] = useState("");
  const [nVuelo, setNVuelo] = useState("");
  const [pax, setPAX] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [tarifa, setTarifa] = useState("");
  const [total, setTotal] = useState("");
  const [totaltyt, setTotalTyt] = useState("");
  const [pais, setPais] = useState("");
  const [aeropuerto, setAeropuerto] = useState("");
  const [cDistritos, setCDistritos] = useState("");
  const [nVoucher, setNVoucher] = useState("");
  const [tipoDeVehiculo, setTipoDeVehiculo] = useState("");
  const [nVehiculos, setNVehiculos] = useState("");
  const [valorVoucher, setValorVoucher] = useState("");
  const [idd, setIdd] = useState("");
  const [Origen, setOrigen] = useState("");
  const [Destino, setDestino] = useState("");
  const [Fecha, setFecha] = useState("");
  const [Hora, setHora] = useState("");
  const [Estado, setEstado] = useState("");
  const [PaxR, setPAXR] = useState("");

  const [VehiculoExtra, setVehiculoExtra] = useState("");
  const [VehiculoNoShow, setVehiculoNoShow] = useState("");
  const [paxNombre, setPAXNombre] = useState("");
  const [validacionProveedor, setValidacionProveedor] = useState("");

  // Detalle Proveedor

  const [VehiculoExtraProvee, setVehiculoExtraProvee] = useState("");
  const [ComentarioExtraProvee, setComentarioExtraProvee] = useState("");
  const [StatusExtra, setStatusExtra] = useState("");

  const [VehiculoNoShowProvee, setVehiculoNoShowProvee] = useState("");
  const [ComentarioNoShowProvee, setComentarioNoShowProvee] = useState("");
  const [StatusNoShow, setStatusNoShow] = useState("");

  const [NombrePax, setNombrePax] = useState("");
  const [PaxTrasladadosProvee, setPaxTrasladadosProvee] = useState("");
  const [StatusPax, setStatusPax] = useState("");

  const [TipoDeVehiculoo, setTipoDeVehiculoo] = useState("");
  const [StatusVehiculo, setStatusVehiculo] = useState("");

  // Detalle Cliente
  const [cliente, setCliente] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [comentario, setComentario] = useState("");

  // Detalle Proveedor
  const [tipoDeVehiculoPro, setTipoDeVehiculoPro] = useState("");
  const [nVehiculosPro, setNVehiculosPro] = useState("");
  const [totalProvee, setTotalProvee] = useState("");
  // Comentarios

  const [comentarioPanel, setComentarioPanel] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  const [statusFINCobro, setStatusFINCobro] = useState("");
  const [statusFINPago, setStatusFINPago] = useState("");
  const [vehiculosFINProveedor, setVehiculosFINProveedor] = useState("");
  const [tarifaIFINProveedor, setTarifaIFINProveedor] = useState("");
  const [comentarioFINProveedor, setComentarioFINProveedor] = useState("");

  const [isInitialLoad, setIsInitialLoad] = useState(false);

  let Datos = {};
  let Viaje = {};
  let DatosCliente = {};
  let DatosProveedor = {};
  let Finanzas = {};

  if (folio) {
    if (folio.id.includes("PISCL")) {
      Datos = {
        terminal,
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      const DatosProveedor = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };

    } else if (folio.id.includes("DPSCL")) {
      Datos = {
        terminal,
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      const DatosProveedor = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };
    } else if (folio.id.includes("CHILP")) {
      Datos = {
        terminal,
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      const DatosProveedor = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };
    } else if (folio.id.includes("SPA")) {
      Datos = {
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      const DatosProveedor = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };
    } else if (folio.id.includes("SE")) {
      Datos = {
        pais,
        aeropuerto,
        cDistritos,
        nVoucher,
        nrt,
        terminal,
        horaVuelo,
        aerolinea,
        nVuelo,
        counter,
        pax,
        selectedOption,
        tipoDeVehiculo,
        nVehiculos,
        total,
        valorVoucher,
        totaltyt,
      };
      DatosProveedor = {
        tipoDeVehiculoPro,
        nVehiculosPro,
        totalProvee,
        comentario,
      };
      const DatosProveedorr = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor: DatosProveedorr,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };
    } else if (folio.id.includes("KPS")) {
      Datos = {
        nrt,
        nSolicitud,
        counter,
        pax,
        selectedOption,
        total,
        totaltyt,
      };
      const DatosProveedor = {
        VehiculoExtra: VehiculoExtraProvee,
        ComentarioExtra: ComentarioExtraProvee,
        StatusExtra,

        VehiculoNoShow: VehiculoNoShowProvee,
        ComentarioNoShow: ComentarioNoShowProvee,
        StatusNoShow,

        NombrePax,
        PaxTrasladados: PaxTrasladadosProvee,
        StatusPax,

        TipoDeVehiculo: TipoDeVehiculoo,
        StatusVehiculo,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        PaxR,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
        paxNombre,
        validacionProveedor,
        DatosProveedor,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      };
      Finanzas = {
        statusFINCobro,
        statusFINPago,
        vehiculosFINProveedor,
        tarifaIFINProveedor,
        comentarioFINProveedor
      };
    }
  }


  const [usuario, setUsuario] = useState({
    uid: "",
    nombre: "",
    apellido: "",
    rol: "",
  });

  const [userId, setUserId] = useState("");

  // useEffect(() => {
  //   const obtenerDatosUsuario = async () => {
  //     try {
  //       const usuarioActual = await consultarAutenticacion();
  //       if (usuarioActual) {
  //         const { uid, nombre, rol, apellido } = usuarioActual;
  //         setUsuario(usuarioActual);
  //         setUserId("HTWsQrbzNVhrHSqrLIlaCzZL71k1")
  //       } else {
  //         console.log("No se ha iniciado sesión o no se encontraron datos de usuario.");
  //       }
  //     } catch (error) {
  //       console.error("Error al obtener datos del usuario:", error);
  //     }
  //   };
  //   obtenerDatosUsuario();
  // }, []);

  // const handleSendMessage = async () => {
  //   try {
  //     const chatId = nrt;
  //     if (folio.Viaje?.VehiculoNoShow !== VehiculoNoShow) {
  //       const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === test);
  //       const mensaje = `${usuario.nombre} ${usuario.apellido} ha modificado el "No show" del Viaje "${viajeEncontrado.VehiculoNoShow}" por "${VehiculoNoShow}" el motivo es "${comentarioPanel}"  `;
  //       await EnviarMensajeChat(chatId, userId, mensaje);
  //       console.log("Mensaje enviado correctamente");
  //     }
  //   } catch (error) {
  //     console.error("Error al enviar el mensaje:", error.message);
  //   }
  // };


  // const GuardarDatos = async () => {
  //   if (
  //     statusFINCobro
  //   ) {
  //     await ActualizarSolicitV2(selectedOption, nrt, Datos, Viaje, DatosCliente, idd, Finanzas);
  //     handleSendMessage();
  //     setMostrarAlertaExito(true);
  //     setOpen(false);
  //   } else {
  //     setMostrarAlerta(true);
  //   }
  // };

  return (
    <>
      {folio && (folio.id.includes("CHILP") || folio.id.includes("DPSCL") || folio.id.includes("SPA") || folio.id.includes("PISCL") || folio.id.includes("KPS")) && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <Paper
            sx={{
              p: 3,
              flexGrow: 1,
              width: "70%",
              maxWidth: "500px",
              maxHeight: "80vh",
              overflowY: "auto",
              backgroundColor: "#white",
              elevation: 24,
              borderRadius: "30px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Container maxWidth="xxl">
              <Box
                component="form"
                noValidate
                autoComplete=""
              >
                <Typography
                  style={{
                    width: '100%',
                    textAlign: "center",
                    backgroundColor: "rgb(17, 25, 42)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  variant="h6"
                  fontWeight="bold"

                >
                  Tarifa
                </Typography>
                <br />
                <Box
                  component="form"
                  noValidate
                  autoComplete=""
                >
                  <Grid item xs={12} sm={6} md={12} container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="statusFINCobro"
                        type="number"
                        label="Tarifa"
                        placeholder="Ingresa la tarifa"
                        value={statusFINCobro}
                        onChange={(e) => setStatusFINCobro(e.target.value)}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <br />
                <Grid item xs={12} sm={6} md={12} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{
                        bgcolor: "#a5d6a7",
                        fontWeight: "bold",
                        color: "green",
                        width: "100%",
                      }}
                      onClick={() => {
                        try {
                          GuardarDatos();
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    >
                      Guardar
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{
                        bgcolor: "#f8bbd0",
                        fontWeight: "bold",
                        color: "red",
                        width: "100%",
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Paper>
        </Modal >
      )}


      {
        folio && (folio.id.includes("SE")) && (
          <Modal open={open} onClose={() => setOpen(false)}>
            <Paper
              sx={{
                p: 3,
                flexGrow: 1,
                width: "70%",
                maxWidth: "500px",
                maxHeight: "80vh",
                overflowY: "auto",
                backgroundColor: "#white",
                elevation: 24,
                borderRadius: "30px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Container maxWidth="xxl">
                <Box
                  component="form"
                  noValidate
                  autoComplete=""
                >
                  <Typography
                    style={{
                      width: '100%',
                      textAlign: "center",
                      backgroundColor: "rgb(17, 25, 42)",
                      color: "white",
                      borderRadius: "30px",
                    }}
                    variant="h6"
                    fontWeight="bold"

                  >
                    Tarifa
                  </Typography>
                  <br />
                  <Box
                    component="form"
                    noValidate
                    autoComplete=""
                  >
                    <Grid item xs={12} sm={6} md={12} container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="statusFINCobro"
                          type="number"
                          label="Tarifa"
                          placeholder="Ingresa la tarifa"
                          value={statusFINCobro}
                          onChange={(e) => setStatusFINCobro(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <br />
                  <Grid item xs={12} sm={6} md={12} container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="outlined"
                        color="success"
                        sx={{
                          bgcolor: "#a5d6a7",
                          fontWeight: "bold",
                          color: "green",
                          width: "100%",
                        }}
                        onClick={() => {
                          try {
                            GuardarDatos();
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        Guardar
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          bgcolor: "#f8bbd0",
                          fontWeight: "bold",
                          color: "red",
                          width: "100%",
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Cerrar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Paper>
          </Modal>
        )
      }
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con exito! , Orden {nrt}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgregarTarifaFINProveedor;
