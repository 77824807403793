import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Container,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AppBar,

} from "@mui/material";
import Image from "mui-image";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from 'react-pdf';
import Logo from '../Imagenes/logo_morado_texto_al_lado.png';

// import {
//   ObtenerDatosProveedoresSolicitud,
//   obtenerPagosFacturasProveedor,
//   obtenerPagosFacturasProveedorv2,
//   GuardarPagosFacturasProveedor,
//   subirArchivoYObtenerURL,
//   eliminarArchivo,
//   actualizarFirestore
// } from "../../APIS/api";

const downloadFilesFromServer = async (fileUrls, fileType) => {
  try {
    console.log(`Downloading files:`, fileUrls, fileType);
    const response = await fetch('https://us-central1-transit-399da.cloudfunctions.net/downloadFiles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fileUrls, fileType })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    saveAs(blob, `${fileType}_files.zip`);
    console.log('Files downloaded and saved successfully');
  } catch (error) {
    console.error('Error downloading files:', error);
  }
};

export function PagosFacturas() {
  const [files, setFiles] = useState(Array(10).fill(null));
  const [fileKeys, setFileKeys] = useState(Array(10).fill(Date.now()));
  const [previewFile, setPreviewFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [mes, setMes] = useState('');
  const [anio, setAnio] = useState('');
  const [counter, setCounter] = useState("B&BN");
  const [counterZ, setCounterZ] = useState([]);
  const [counterZO, setCounterZO] = useState([]);
  const [status, setStatus] = useState(false);
  const [semanas, setSemanas] = useState(Array.from({ length: 5 }, () => ({
    semana: '',
    valorFactura: '',
    montoPago: '',
    urlFactura: null,
    urlComprobante: null
  })));

  const navigate = useNavigate();

  useEffect(() => {
    const Listas = async () => {
      const ProveedorO = "1" // await ObtenerDatosProveedoresSolicitud();
      setCounterZ(ProveedorO);
    };
    Listas();
  }, []);

  useEffect(() => {
    const fetchProveedorO = async () => {
      if (mes && anio) {
        const id = `${mes} - ${anio}`;
        const ProveedorRO = "1" // await obtenerPagosFacturasProveedorv2(id);

        // Merging data
        const updatedCounterZ = counterZ.map((counter) => {
          const foundData = ProveedorRO && ProveedorRO.datos ? ProveedorRO.datos.find(data => data.counter === counter.id) : null;
          return {
            ...counter,
            hasData: foundData ? foundData.semanas.map(semana => ({
              semana: semana.semana,
              hasFactura: !!semana.urlFactura,
              hasComprobante: !!semana.urlComprobante,
              montoPago: !!semana.montoPago
            })) : []
          };
        });

        setCounterZO(updatedCounterZ);
      }
    };
    fetchProveedorO();
  }, [mes, anio, counterZ, status]);

  useEffect(() => {
    if (mes && anio && counter) {
      setStatus(true);
      // cargarDatos();
    }
  }, [mes, anio, counter, status]);

  const cargarDatos = async () => {
    const id = `${mes} - ${anio}`;
    // const datosProveedor = await obtenerPagosFacturasProveedor(id, counter);
    if (datosProveedor) {
      setSemanas(datosProveedor.semanas);
    } else {
      setSemanas(Array.from({ length: 5 }, () => ({
        semana: '',
        valorFactura: '',
        montoPago: '',
        urlFactura: null,
        urlComprobante: null
      })));
    }
  };

  const handleChangeMes = (event) => {
    setMes(event.target.value);
  };

  const handleChangeAnio = (event) => {
    setAnio(event.target.value);
  };

  const handleFileChange = (e, index) => {
    if (e.target.files[0]) {
      const newFiles = [...files];
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
    }
  };

  const handleRemoveFile = (index) => {
    setFileToDelete(index);
    setConfirmOpen(true);
  };

  const confirmRemoveFile = async () => {
    const index = fileToDelete;
    const fileURL = index % 2 === 0 ? semanas[Math.floor(index / 2)].urlFactura : semanas[Math.floor(index / 2)].urlComprobante;

    if (fileURL) {
      await eliminarArchivo(fileURL);
    }

    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);

    const newFileKeys = [...fileKeys];
    newFileKeys[index] = Date.now();
    setFileKeys(newFileKeys);

    const newSemanas = [...semanas];
    if (index % 2 === 0) {
      newSemanas[Math.floor(index / 2)].urlFactura = null;
    } else {
      newSemanas[Math.floor(index / 2)].urlComprobante = null;
    }
    setSemanas(newSemanas);

    const id = `${mes} - ${anio}`;
    await actualizarFirestore(id, counter, newSemanas);

    setConfirmOpen(false);
    setFileToDelete(null);
  };

  const handleViewFile = (fileURL, fileType) => {
    setPreviewFile(fileURL);
    setFileType(fileType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPreviewFile(null);
  };

  const handleSemanaChange = (e, weekIndex, field) => {
    const newSemanas = [...semanas];
    newSemanas[weekIndex] = {
      ...newSemanas[weekIndex],
      [field]: e.target.value
    };
    setSemanas(newSemanas);
  };

  const renderWeek = (weekIndex) => {
    const file1Index = weekIndex * 2;
    const file2Index = file1Index + 1;
    return (
      <Grid item xs={2.3} key={weekIndex}>
        <TextField
          label={`Semana ${weekIndex + 1}`}
          variant="outlined"
          fullWidth
          value={semanas[weekIndex].semana}
          onChange={(e) => handleSemanaChange(e, weekIndex, 'semana')}
        />
        <p />
        <input
          key={fileKeys[file1Index]}
          id={`file1-upload-${weekIndex}`}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e, file1Index)}
        />
        <label htmlFor={`file1-upload-${weekIndex}`}>
          <Button
            sx={{
              width: "100%",
              height: "57px",
              bgcolor: "Green",
              color: "white",
              fontWeight: "bold",
              border: "3px solid Green",
              transition: "background-color 0.3s ease-in-out",
              "&:hover": {
                bgcolor: "#8bc34a",
              },
            }}
            component="span"
          >
            Factura
          </Button>
        </label>
        {semanas[weekIndex].urlFactura && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <IconButton onClick={() => handleRemoveFile(file1Index)}>
              <DeleteIcon />
            </IconButton>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={() => handleViewFile(semanas[weekIndex].urlFactura, 'application/pdf')}
            >
              Ver el documento
            </Button>
          </Box>
        )}
        <p />
        <TextField
          label="Valor Factura"
          variant="outlined"
          fullWidth
          value={semanas[weekIndex].valorFactura}
          onChange={(e) => handleSemanaChange(e, weekIndex, 'valorFactura')}
        />
        <p />
        <input
          key={fileKeys[file2Index]}
          accept="image/*,.pdf" // Accepting images and PDFs
          id={`file2-upload-${weekIndex}`}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e, file2Index)}
        />
        <label htmlFor={`file2-upload-${weekIndex}`}>
          <Button
            sx={{
              width: "100%",
              height: "57px",
              bgcolor: "Green",
              color: "white",
              fontWeight: "bold",
              border: "3px solid Green",
              transition: "background-color 0.3s ease-in-out",
              "&:hover": {
                bgcolor: "#8bc34a",
              },
            }}
            component="span"
          >
            Comprobante
          </Button>
        </label>
        {semanas[weekIndex].urlComprobante && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <IconButton onClick={() => handleRemoveFile(file2Index)}>
              <DeleteIcon />
            </IconButton>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={() => handleViewFile(semanas[weekIndex].urlComprobante, 'application/pdf')}
            >
              Ver el documento
            </Button>
          </Box>
        )}
        <p />
        <TextField
          label="Monto del pago"
          variant="outlined"
          fullWidth
          value={semanas[weekIndex].montoPago}
          onChange={(e) => handleSemanaChange(e, weekIndex, 'montoPago')}
        />
        <p />
      </Grid>
    );
  };

  const handleSave = async () => {
    const id = `${mes} - ${anio}`;
    const semanaData = await Promise.all(semanas.map(async (semana, index) => {
      const file1Index = index * 2;
      const file2Index = file1Index + 1;

      const fileURLFactura = files[file1Index] ? await subirArchivoYObtenerURL(files[file1Index], id, `Semana ${index + 1}`, counter) : semana.urlFactura;
      const fileURLComprobante = files[file2Index] ? await subirArchivoYObtenerURL(files[file2Index], id, `Semana ${index + 1}`, counter) : semana.urlComprobante;

      return {
        semana: semana.semana,
        urlFactura: fileURLFactura,
        valorFactura: semana.valorFactura,
        urlComprobante: fileURLComprobante,
        montoPago: semana.montoPago,
      };
    }));

    const nuevoProveedor = {
      counter,
      semanas: semanaData,
    };

    try {
      await GuardarPagosFacturasProveedor(id, nuevoProveedor);
      alert("Datos guardados correctamente");
      setStatus(false);
    } catch (error) {
      console.error("Error guardando los datos: ", error);
      alert("Error guardando los datos");
      setStatus(false);
    }
  };

  const fetchFileAsBlob = async (url) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
      });
      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }
      return await response.blob();
    } catch (error) {
      console.error(`Failed to fetch URL: ${url}`, error);
      return null;
    }
  };

  const handleDownloadAllFacturas = async () => {
    const fileUrls = semanas.filter(s => s.urlFactura).map(s => s.urlFactura);
    if (fileUrls.length) {
      await downloadFilesFromServer(fileUrls, 'Facturas');
    } else {
      alert('No facturas available to download.');
    }
  };

  const handleDownloadAllComprobantes = async () => {
    const fileUrls = semanas.filter(s => s.urlComprobante).map(s => s.urlComprobante);
    if (fileUrls.length) {
      await downloadFilesFromServer(fileUrls, 'Comprobantes');
    } else {
      alert('No comprobantes available to download.');
    }
  };

  const renderOption = (option) => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="body1">{option.id}</Typography>
        {option.hasData.length ? (
          option.hasData.map((data, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography variant="body2">{`Semana ${index + 1}`}</Typography>
              {console.log("Semana", data.semana, "Monto", data.montoPago)}
              {data.montoPago ? (
                <CheckIcon sx={{ color: "green", ml: 1 }} />
              ) : (
                <CloseIcon sx={{ color: "red", ml: 1 }} />
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">No data</Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      <React.Fragment>
        <AppBar
          position="static"
          sx={{
            bgcolor: '#8c52fe',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              textAlign: 'center',
              padding: '10px',
            }}
          >
            <Image
              src={Logo}
              alt="Logo"
              style={{
                width: '100%',
                maxWidth: '250px',
                height: 'auto',
                margin: '0 auto',
              }}
              onClick={() => navigate('/PortalProveedor')}
            />
            <Stack direction="row" justifyContent="center" mt={2}>
              <Button
                sx={{
                  height: 57.5,
                  bgcolor: '#8c52fe',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'white',
                    color: '#8c52fe',
                    border: '1px solid rgb(255, 255, 255)',
                  },
                }}
                variant="outlined"
                onClick={() => navigate('/PanelDeControlProveeFinanzas')}
              >
                Panel Finanzas
              </Button>
              <Button
                sx={{
                  height: 57.5,
                  bgcolor: '#8c52fe',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'white',
                    color: '#8c52fe',
                    border: '1px solid rgb(255, 255, 255)',
                  },
                }}
                variant="outlined"
                onClick={() => navigate('/PagosFacturas')}
              >
                Panel Facturas
              </Button>
            </Stack>
          </Box>
        </AppBar>

        <br />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <br />
          <Container maxWidth="xxl">
            <Stack spacing={3}>
              <Paper
                sx={{
                  p: 3,
                  flexGrow: 1,
                  backgroundColor: "white",
                  elevation: 30,
                  borderRadius: "20px",
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      backgroundColor: "rgb(17, 25, 42)",
                      color: "white",
                      borderRadius: "30px",
                    }}
                    variant="h6"
                    fontWeight="bold"
                  >
                    Pago de Facturas
                  </Typography>
                </Stack>
                <br />
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="mes-label">Selecciona un mes</InputLabel>
                      <Select
                        labelId="mes-label"
                        value={mes}
                        onChange={handleChangeMes}
                        label="Selecciona un mes"
                      >
                        <MenuItem value="Enero">Enero</MenuItem>
                        <MenuItem value="Febrero">Febrero</MenuItem>
                        <MenuItem value="Marzo">Marzo</MenuItem>
                        <MenuItem value="Abril">Abril</MenuItem>
                        <MenuItem value="Mayo">Mayo</MenuItem>
                        <MenuItem value="Junio">Junio</MenuItem>
                        <MenuItem value="Julio">Julio</MenuItem>
                        <MenuItem value="Agosto">Agosto</MenuItem>
                        <MenuItem value="Septiembre">Septiembre</MenuItem>
                        <MenuItem value="Octubre">Octubre</MenuItem>
                        <MenuItem value="Noviembre">Noviembre</MenuItem>
                        <MenuItem value="Diciembre">Diciembre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="anio-label">Selecciona un año</InputLabel>
                      <Select
                        labelId="anio-label"
                        value={anio}
                        onChange={handleChangeAnio}
                        label="Selecciona un año"
                      >
                        {Array.from({ length: 100 }, (_, index) => (
                          <MenuItem key={index} value={2024 - index}>
                            {2024 - index}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
                <Grid container justifyContent="center" spacing={2}>
                  {Array.from({ length: 5 }).map((_, weekIndex) => renderWeek(weekIndex))}
                </Grid>
                <br />
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button variant="contained" color="primary" onClick={handleSave}>
                    Guardar
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleDownloadAllFacturas}>
                    Descargar Todas las Facturas
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleDownloadAllComprobantes}>
                    Descargar Todos los Comprobantes
                  </Button>
                </Stack>
              </Paper>
            </Stack>
          </Container>
          <br />
        </Box>
      </React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Ver Documento
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => {
              const link = document.createElement('a');
              link.href = previewFile;
              link.download = `document-${Date.now()}`;
              link.click();
            }}
          >
            Descargar
          </Button>
        </DialogTitle>
        <DialogContent>
          {fileType === "application/pdf" ? (
            <Document file={previewFile}>
              <Page pageNumber={1} />
            </Document>
          ) : (
            <img src={previewFile} alt="Preview" style={{ width: '100%' }} />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que quieres eliminar este archivo?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={confirmRemoveFile} color="secondary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PagosFacturas;
