import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import {
  Box,
  Grid,
  Paper,
  Typography,
  Container,
  TextField,
  Stack,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  MenuItem,
  CircularProgress,
  AppBar,
  Button,
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";


import { obtenerDatosDeProvedores } from "../../APIS/apiProveedor";
import AgregarViajeProveedor from "./Proveedor/AgregarViajeProveedor";
import AgregarVehiculoProveedor from "./Proveedor/AgregarVehiculoProveedor";
import AgregarPaxProveedor from "./Proveedor/AgregarPaxProveedor";
import AgregarNoShowProveedor from "./Proveedor/AgregarNoShowProveedor";
import AgregarExtraProveedor from "./Proveedor/AgregarExtraProveedor";
import AgregarFechaHora from "./Proveedor/AgregarFechaHora";

import Image from "mui-image";
import Logo from '../Imagenes/logo_morado_texto_al_lado.png';
import * as XLSX from "xlsx";



const Estado = [
  { id: 1, label: "Pendiente" },
  { id: 2, label: "Cancelado" },
  { id: 3, label: "Proximamente" },
  { id: 4, label: "Activo" },
  { id: 5, label: "En curso" },
  { id: 6, label: "Completado" },
  { id: 7, label: "Por Validar" },
];

const getBackgroundColor = (label) => {
  switch (label) {
    case "Pendiente":
      return "#616161";
    case "Cancelado":
      return "#F44336";
    case "Cancelado API":
      return "#E91E63";
    case "Proximamente":
      return "#3F51B5";
    case "Activo":
      return "#2196F3";
    case "En curso":
      return "#FFC107";
    case "Completado":
      return "#4CAF50";
    case "Por Validar":
      return "#9C27B0";
    default:
      return "transparent";
  }
};

const getStatusIcon = (status) => {
  if (status === "Pendiente T&T") {
    return <WarningAmberIcon style={{ color: "orange", fontSize: 30 }} />;
  }
  if (status === "Aprobado") {
    return <CheckCircleOutlineIcon style={{ color: "green", fontSize: 30 }} />;
  }
  if (status === "Rechazado") {
    return <CancelIcon style={{ color: "red", fontSize: 30 }} />;
  }
  return null;
};

const estadosOpciones = [
  "Cancelado",
  "Proximamente",
  "Activo",
  "En curso",
  "Por Validar",
];

const useBlinkingStyle = () => {
  const [blinking, setBlinking] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      setBlinking((prev) => !prev);
    }, 500); // Toggle every 500 ms for a blinking effect
    return () => clearInterval(timer);
  }, []);
  return blinking ? { backgroundColor: "rgba(255, 0, 0, 0.2)" } : {};
};


export function PanelDeControlProvee() {
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState("");
  const [estados, setEstados] = useState(estadosOpciones);
  const [buscador, setBuscador] = useState("");
  const [status, setStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Nuevo estado de edición global
  const [rows, setRows] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [loading, setLoading] = useState(false);



  const navigate = useNavigate();
  const isMounted = useRef(true);


  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      navigate("/login");
    }
    const handleBeforeUnload = () => {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("counter");
      localStorage.removeItem("firstname");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    const storedCounter = localStorage.getItem("counter");
    if (storedCounter) {
      setCounter(storedCounter);
    }
  }, []);

  const [fechaInicio, setFechaInicio] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [fechaTermino, setFechaTermino] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );


  const fechaMaximaInicio = moment().subtract(2, "weeks").format("YYYY-MM-DD");
  const fechaActual = moment().format("YYYY-MM-DD");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (counter === "" || isEditing) { // No sincroniza si está en edición
      return;
    } else {
      setLoading(true);
      const unsubscribe = obtenerDatosDeProvedores(
        counter,
        fechaInicio,
        fechaTermino,
        estados,
        (data) => {
          setOriginalRow(data);
          console.log(data)
          if (buscador) {
            test(buscador);
          } else {
            setRows(data);
          }
          setLoading(false);
        }
      );
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [counter, estados, fechaInicio, fechaTermino, buscador, isEditing]);

  const test = (buscador) => {
    if (buscador === "") {
      setRows(originalRow);
    } else {
      const resultadosBusqueda = originalRow.filter((solicitud) => {
        const searchInObject = (obj) => {
          for (let key in obj) {
            if (typeof obj[key] === "object" && obj[key] !== null) {
              if (searchInObject(obj[key])) {
                return true;
              }
            } else if (
              obj[key] &&
              obj[key].toString().toLowerCase().includes(buscador.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        };
        return searchInObject(solicitud);
      });
      setRows(resultadosBusqueda); // Actualiza los datos con los resultados filtrados
    }
  };

  const handleOnExportListado = () => {
    // Datos para exportar
    const sheetData = rows.map((rowData) => {
      const { Datos, DatosCliente, DatosProveedor, Viaje } = rowData;

      // Construir cada fila de datos
      return Viaje.map((viaje, index) => ({
        Aerolinea: Datos?.aerolinea || "N/A",
        Aeropuerto: Datos?.aeropuerto || "N/A",
        Distritos: Datos?.cDistritos || "N/A",
        NRT: Datos?.nrt || "N/A",
        "Número de Vuelo": Datos?.nVuelo || "N/A",
        Pax: Datos?.pax || "N/A",
        "Destino del viaje": viaje?.Destino || "N/A",
        "Estado del viaje": viaje?.Estado || "N/A",
        "Fecha del viaje": viaje?.Fecha || "N/A",
        "Hora del viaje": viaje?.Hora || "N/A",
        "Origen del viaje": viaje?.Origen || "N/A",
        "Vehículo Extra": viaje?.VehiculoExtra || "0",
        "Vehículo No Show": viaje?.VehiculoNoShow || "0",
        "Proveedor - Tipo de Vehículo": DatosProveedor?.tipoDeVehiculoPro || "N/A",
        // "Proveedor - Número de Vehículos": DatosProveedor?.nVehiculosPro || "0",
        // "Proveedor - Total": DatosProveedor?.totalProvee || "N/A",
      }));
    }).flat(); // Aplanar el array para tener una fila por viaje

    // Verificar si hay datos para exportar
    if (sheetData.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(wb, ws, "Listado de Reportes");
      XLSX.writeFile(wb, "Listado_de_Reportes.xlsx");
    } else {
      console.log("No hay datos para exportar.");
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: '#8c52fe',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '600px',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          <Image
            src={Logo}
            alt="Logo"
            style={{
              width: '100%',
              maxWidth: '250px',
              height: 'auto',
              margin: '0 auto',
            }}
            onClick={() => navigate('/PortalProveedor')}
          />
          {/* <Stack direction="row" justifyContent="center" mt={2}>
            <Button
              sx={{
                height: 57.5,
                bgcolor: '#8c52fe',
                color: 'white',
                '&:hover': {
                  bgcolor: 'white',
                  color: '#8c52fe',
                  border: '1px solid rgb(255, 255, 255)',
                },
              }}
              variant="outlined"
              onClick={() => navigate('/PanelDeControlProveeFinanzas')}
            >
              Panel Finanzas
            </Button>
            <Button
              sx={{
                height: 57.5,
                bgcolor: '#8c52fe',
                color: 'white',
                '&:hover': {
                  bgcolor: 'white',
                  color: '#8c52fe',
                  border: '1px solid rgb(255, 255, 255)',
                },
              }}
              variant="outlined"
              onClick={() => navigate('/PagosFacturas')}
            >
              Panel Facturas
            </Button>
          </Stack> */}
        </Box>
      </AppBar>

      <br />
      <Container maxWidth="xxl">
        <Stack spacing={0}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "rgb(17, 25, 42)",
                color: "white",
                borderRadius: "30px",
              }}
              variant="h6"
              fontWeight="bold"
            >
              PANEL DE SOLICITUDES
            </Typography>
          </Stack>
        </Stack>
        <br />
        <Grid container justifyContent="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                id={`outlined-requi#F44336-`}
                label="Buscador (completo)"
                type="text"
                sx={{ width: "100%", marginRight: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={buscador}
                onChange={(e) => {
                  const value = e.target.value;
                  setBuscador(value);
                  test(value);
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <TextField
                id="outlined-proveedor"
                label="Proveedor"
                sx={{ width: "100%" }}
                value={counter}
                InputLabelProps={{
                  shrink: true,
                }}
                error={counter.trim() === ""}
                helperText={counter.trim() === "" ? "Este campo no puede estar vacío" : ""}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label">Estado</InputLabel>
                <Select
                  label="Estado"
                  id="outlined-estado"
                  multiple
                  value={estados}
                  onChange={(e) => setEstados(e.target.value)}
                  input={<OutlinedInput label="Estado" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={{ sx: { width: "100%" } }}
                >
                  {Estado.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.label}
                      style={{
                        backgroundColor: getBackgroundColor(option.label),
                      }}
                    >
                      <Checkbox checked={estados.indexOf(option.label) > -1} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <TextField
                id="outlined-fecha-inicio"
                label="Fecha Inicio"
                sx={{ width: "100%" }}
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                inputProps={{
                  min: fechaMaximaInicio, // Limitar a 2 semanas atrás
                  max: fechaActual, // No permitir fechas futuras
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <TextField
                id="outlined-fecha-termino"
                label="Fecha Termino"
                sx={{ width: "100%" }}
                value={fechaTermino}
                onChange={(e) => setFechaTermino(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                inputProps={{
                  min: fechaInicio, // Fecha mínima es la fecha de inicio
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Button
                sx={{
                  height: 57.5,
                  width: "100%",
                  bgcolor: '#8c52fe',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'white',
                    color: '#8c52fe',
                    border: '1px solid #8c52fe', // Agrega un borde morado para mayor contraste
                  },
                }}
                variant="outlined"
                onClick={handleOnExportListado}
              >
                Excel
                <FileDownloadIcon />
                (Proximamente)
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {loading ? ( // Mostrar el círculo de carga si loading es true
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: 80 }}>
                    Estados
                  </TableCell>
                  <TableCell align="left" style={{ width: 80 }}>
                    ID
                  </TableCell>
                  <TableCell align="left" style={{ width: 80 }}>
                    N°/Vuelo
                  </TableCell>
                  <TableCell align="left" style={{ width: 80 }}>
                    Pax
                  </TableCell>
                  <TableCell align="left" style={{ width: 80 }}>
                    V/A
                  </TableCell>

                  <TableCell align="left" style={{ width: 80 }}>
                    Agregar Viaje
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((rowData, index) => (
                  <Row key={index} Mapeo={rowData} setIsEditing={setIsEditing} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Box>
  );
}

function Row({ Mapeo, setIsEditing }) {
  const [open, setOpen] = useState(false);
  const [openFechaHora, setOpenFechaHora] = useState(false);
  const [openAgregar, setOpenAgregar] = useState(false);
  const [openPax, setOpenPax] = useState(false);
  const [openNoShow, setOpenNoShow] = useState(false);
  const [openExtras, setOpenExtras] = useState(false);
  const [openVehiculo, setOpenVehiculo] = useState(false);
  const [Idd, setIdd] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [hidden, setHidden] = useState(false);

  const primerViaje = Mapeo.Viaje?.find(
    (historyRow) =>
      historyRow.Estado === "Activo" ||
      historyRow.Estado === "En curso" ||
      historyRow.Estado === "Proximamente"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh((prev) => prev + 1); // Change state to trigger re-render
    }, 60000); // Update every 1 minute

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  const blinkingStyle = useBlinkingStyle();

  const convertFirestoreTimestampToDate = (timestamp) => {
    if (!timestamp) return null;
    return new Date(timestamp.seconds * 1000);
  };

  const isNewService = (fechaCounterIngresada) => {
    const fecha = convertFirestoreTimestampToDate(fechaCounterIngresada);
    if (!fecha) return false; // Handle if date is invalid
    return moment().diff(fecha, "minutes") <= 30;
  };

  const getTimeZoneByAirport = (airport) => {
    switch (airport) {
      case "LIM":
        return "America/Lima";
      case "EZE":
      case "AEP":
        return "America/Argentina/Buenos_Aires";
      case "MEX":
      case "TLC":
      case "GLD":
        return "America/Mexico_City";
      default:
        return "UTC"; // Fallback to UTC if unknown
    }
  };

  const getTrafficLightColor = (fechaCounterIngresada) => {
    const fecha = convertFirestoreTimestampToDate(fechaCounterIngresada);
    if (!fecha) return null; // No date means no indicator

    const diffInMinutes = moment().diff(fecha, "minutes");

    if (diffInMinutes < 5) {
      return "green"; // Less than 20 minutes old
    } else if (diffInMinutes < 10) {
      return "yellow"; // Between 20 and 30 minutes
    } else {
      return "red"; // More than 30 minutes
    }
  };




  const handleOpenViaje = () => {
    setIsEditing(true);
    setOpenAgregar(true);
  };

  const handleCloseViaje = () => {
    setIsEditing(false);
    setOpenAgregar(false);
  };

  const handleOpenFechaHora = (idd) => {
    setIsEditing(true);
    setOpenFechaHora(true);
    setIdd(idd);
  };

  const handleCloseFechaHora = () => {
    setIsEditing(false);
    setOpenFechaHora(false);
  };

  const handleOpenVehiculo = (idd) => {
    setIsEditing(true);
    setOpenVehiculo(true);
    setIdd(idd);
  };

  const handleCloseVehiculo = () => {
    setIsEditing(false);
    setOpenVehiculo(false);
  };


  const handleOpenPax = (idd) => {
    setIsEditing(true);
    setOpenPax(true);
    setIdd(idd);
  };

  const handleClosePax = () => {
    setIsEditing(false);
    setOpenPax(false);
  };

  const handleOpenNoShow = (idd) => {
    setIsEditing(true);
    setOpenNoShow(true);
    setIdd(idd);
  };

  const handleCloseNoShow = () => {
    setIsEditing(false);
    setOpenNoShow(false);
  };

  const handleOpenExtras = (idd) => {
    setIsEditing(true);
    setOpenExtras(true);
    setIdd(idd);
  };

  const handleCloseExtras = () => {
    setIsEditing(false);
    setOpenExtras(false);
  };


  return (
    <>
      <React.Fragment>
        <TableRow>
          <TableCell align="left" style={{ width: 80, height: 20 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>

            {primerViaje && (
              <>
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: "10px",
                    marginLeft: 35,
                    border: "1px solid #000",
                    width: 60,
                    height: 20,
                    backgroundColor:
                      primerViaje.Estado === "Proximamente"
                        ? "#3F51B5"
                        : primerViaje.Estado === "Activo"
                          ? "#2196F3"
                          : primerViaje.Estado === "En curso"
                            ? "#FFC107"
                            : "defaultColor",
                    marginTop: -30,
                  }}
                >
                  {primerViaje.Hora}
                </div>
              </>
            )}
          </TableCell>
          <TableCell align="left" style={{ width: 80 }}>
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              {Mapeo.Datos.nrt}
              {Mapeo.Datos.fechaCounterIngresada &&
                isNewService(Mapeo.Datos.fechaCounterIngresada) &&
                !Mapeo.Viaje.some((viaje) => viaje.Estado === "Completado") &&
                !hidden && ( // Check if not hidden
                  <div
                    style={{
                      ...blinkingStyle,
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                      padding: "3px 8px",
                      borderRadius: "12px",
                    }}
                  >
                    <AccessTimeIcon
                      style={{
                        color: getTrafficLightColor(Mapeo.Datos.fechaCounterIngresada),
                        marginRight: "5px",
                      }}
                    />
                    <span style={{ color: "#d32f2f", fontWeight: "bold" }}>Servicio nuevo</span>
                    <IconButton
                      size="small"
                      onClick={() => setHidden(true)}
                      style={{ marginLeft: "5px" }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
            </span>
          </TableCell>
          <TableCell align="left" style={{ width: 80 }}>
            {Mapeo.Datos.nVuelo}
          </TableCell>
          <TableCell align="left" style={{ width: 80 }}>
            {Mapeo.Datos.pax}
          </TableCell>
          <TableCell align="left" style={{ width: 80 }}>
            {Math.ceil(Mapeo.DatosProveedor?.nVehiculosPro / 2) || 1}
          </TableCell>

          <TableCell component="th" scope="row" style={{ width: 30 }}>
            <IconButton
              sx={{
                bgcolor: "#52b202",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                border: "1px solid 3f51b5",
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  bgcolor: "#76ff03",
                },
              }}
              color="success"
              onClick={() => handleOpenViaje()}

            >
              <AddCircleIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Estados</TableCell>
                      <TableCell style={{ width: 80 }}>Fecha</TableCell>
                      <TableCell>Hora</TableCell>
                      <TableCell>Origen</TableCell>
                      <TableCell>Destino</TableCell>
                      <TableCell>Tipo de vehiculo</TableCell>
                      <TableCell style={{ width: 70 }}>Pax trans</TableCell>
                      <TableCell style={{ width: 70 }}>No Show</TableCell>
                      <TableCell style={{ width: 70 }}>Extras</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Mapeo.Viaje.map((historyRow) => (
                      <TableRow key={historyRow.idd}>
                        <TableCell>
                          <div>
                            {historyRow?.Estado && (
                              <>
                                {(() => {
                                  let Estado = historyRow?.Estado || "Pendiente";
                                  let backgroundColor = "";
                                  let borderStyle = "1px solid #000"; // Estilo de borde por defecto

                                  switch (Estado) {
                                    case "Pendiente":
                                      backgroundColor = "#616161";
                                      break;
                                    case "Cancelado":
                                      backgroundColor = "#F44336";
                                      break;
                                    case "Cancelado API":
                                      backgroundColor = "#E91E63";
                                      break;
                                    case "Completado":
                                      backgroundColor = "#4CAF50";
                                      break;
                                    case "CompletadoS":
                                      backgroundColor = "#a2cf6e";
                                      break;
                                    case "Activo":
                                      backgroundColor = "#2196F3";
                                      break;
                                    case "Por asignar":
                                      backgroundColor = "white";
                                      break;
                                    case "En curso":
                                      backgroundColor = "#FFC107";
                                      break;
                                    case "Proximamente":
                                      backgroundColor = "#3F51B5";
                                      break;
                                    case "Por Validar":
                                      backgroundColor = "#673ab7";
                                      borderStyle = "5px solid #ff1744";
                                      break;
                                    default:
                                      break;
                                  }
                                  return (
                                    <div
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <IconButton
                                        sx={{
                                          width: 100,
                                          height: 25,
                                          borderRadius: 20,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: borderStyle, // Usar el estilo de borde definido en el switch
                                          backgroundColor: backgroundColor,
                                          overflow: "hidden", // Para evitar el desbordamiento del texto
                                          whiteSpace: "nowrap", // Mantener el texto en una línea
                                        }}
                                        onClick={() => handleOpenFechaHora(historyRow.idd)}
                                      >
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            textOverflow: "ellipsis", // Para manejar el texto largo con puntos suspensivos
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {historyRow.Fecha}
                                        </Typography>
                                      </IconButton>
                                      {historyRow.DatosProveedor.StatusFechaHora === "Aceptado" && (
                                        <CheckCircleOutlineIcon
                                          sx={{ color: "green", ml: "8px" }}
                                        />
                                      )}
                                      {historyRow.DatosProveedor.StatusFechaHora ===
                                        "Cancelado" && (
                                          <CancelIcon sx={{ color: "red", ml: "8px" }} />
                                        )}
                                      {historyRow.DatosProveedor.StatusFechaHora ===
                                        "En espera" && (
                                          <WarningAmberIcon
                                            sx={{ color: "#f9a825", ml: "8px" }}
                                          />
                                        )}
                                    </div>
                                  );
                                })()}

                              </>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{historyRow.Fecha}</TableCell>
                        <TableCell>{historyRow.Hora}</TableCell>
                        <TableCell>{historyRow.Origen}</TableCell>
                        <TableCell>{historyRow.Destino}</TableCell>
                        <TableCell align="left" style={{ width: 120 }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconButton
                              sx={{
                                bgcolor: "#f9a825",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "20px",
                                height: "35px",
                                width: "80px",
                                border: "1px solid #f9a825",
                                transition: "background-color 0.3s ease-in-out",
                                "&:hover": {
                                  bgcolor: "#fdd835",
                                },
                              }}
                              aria-label="Vehiculo"
                              onClick={() => handleOpenVehiculo(historyRow.idd)}
                              color="error"
                            >
                              {historyRow.DatosProveedor.TipoDeVehiculo}
                            </IconButton>
                            {historyRow.DatosProveedor.StatusVehiculo ===
                              "Aceptado" && (
                                <CheckCircleOutlineIcon
                                  sx={{ color: "green", ml: "8px" }}
                                />
                              )}
                            {historyRow.DatosProveedor.StatusVehiculo ===
                              "Cancelado" && (
                                <CancelIcon sx={{ color: "red", ml: "8px" }} />
                              )}
                            {historyRow.DatosProveedor.StatusVehiculo ===
                              "En espera" && (
                                <WarningAmberIcon
                                  sx={{ color: "#f9a825", ml: "8px" }}
                                />
                              )}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconButton
                              sx={{
                                bgcolor: "#f9a825",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "20px",
                                height: "35px",
                                width: "35px",
                                border: "1px solid #f9a825",
                                transition: "background-color 0.3s ease-in-out",
                                "&:hover": {
                                  bgcolor: "#fdd835",
                                },
                              }}
                              aria-label="Pax"
                              onClick={() => handleOpenPax(historyRow.idd)}
                              color="error"
                            >
                              {historyRow.DatosProveedor.PaxTrasladados}
                            </IconButton>
                            {historyRow.DatosProveedor.StatusPax ===
                              "Aceptado" && (
                                <CheckCircleOutlineIcon
                                  sx={{ color: "green", ml: "8px" }}
                                />
                              )}
                            {historyRow.DatosProveedor.StatusPax ===
                              "Cancelado" && (
                                <CancelIcon sx={{ color: "red", ml: "8px" }} />
                              )}
                            {historyRow.DatosProveedor.StatusPax ===
                              "En espera" && (
                                <WarningAmberIcon
                                  sx={{ color: "#f9a825", ml: "8px" }}
                                />
                              )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconButton
                              sx={{
                                bgcolor: "#f9a825",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "20px",
                                height: "35px",
                                width: "35px",
                                border: "1px solid #f9a825",
                                transition: "background-color 0.3s ease-in-out",
                                "&:hover": {
                                  bgcolor: "#fdd835",
                                },
                              }}
                              aria-label="NoShow"
                              onClick={() => handleOpenNoShow(historyRow.idd)}
                              color="error"
                            >
                              {historyRow.DatosProveedor.VehiculoNoShow}
                            </IconButton>
                            {historyRow.DatosProveedor.StatusNoShow ===
                              "Aceptado" && (
                                <CheckCircleOutlineIcon
                                  sx={{ color: "green", ml: "8px" }}
                                />
                              )}
                            {historyRow.DatosProveedor.StatusNoShow ===
                              "Cancelado" && (
                                <CancelIcon sx={{ color: "red", ml: "8px" }} />
                              )}
                            {historyRow.DatosProveedor.StatusNoShow ===
                              "En espera" && (
                                <WarningAmberIcon
                                  sx={{ color: "#f9a825", ml: "8px" }} />
                              )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconButton
                              sx={{
                                bgcolor: "#52b202",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: "20px",
                                border: "1px solid 3f51b5",
                                height: "35px",
                                width: "35px",
                                transition: "background-color 0.3s ease-in-out",
                                "&:hover": {
                                  bgcolor: "#76ff03",
                                },
                              }}
                              aria-label="Extras"
                              onClick={() => handleOpenExtras(historyRow.idd)}
                              color="info"
                            >
                              {historyRow.DatosProveedor.VehiculoExtra}
                            </IconButton>
                            {historyRow.DatosProveedor.StatusExtra === "Aceptado" && (
                              <CheckCircleOutlineIcon
                                sx={{ color: "green", ml: "8px" }}
                              />
                            )}
                            {historyRow.DatosProveedor.StatusExtra ===
                              "Cancelado" && (
                                <CancelIcon sx={{ color: "red", ml: "8px" }} />
                              )}
                            {historyRow.DatosProveedor.StatusExtra ===
                              "En espera" && (
                                <WarningAmberIcon
                                  sx={{ color: "#f9a825", ml: "8px" }}
                                />
                              )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <AgregarFechaHora
          open={openFechaHora}
          setOpen={handleCloseFechaHora}
          folio={Mapeo}
          Idd={Idd}
        />
        <AgregarViajeProveedor
          open={openAgregar}
          setOpen={handleCloseViaje}
          folio={Mapeo}
          test={Idd}
        />
        <AgregarVehiculoProveedor
          open={openVehiculo}
          setOpen={handleCloseVehiculo}
          folio={Mapeo}
          Idd={Idd}
        />
        <AgregarPaxProveedor
          open={openPax}
          setOpen={handleClosePax}
          folio={Mapeo}
          Idd={Idd}
        />
        <AgregarNoShowProveedor
          open={openNoShow}
          setOpen={handleCloseNoShow}
          folio={Mapeo}
          Idd={Idd}
        />
        <AgregarExtraProveedor
          open={openExtras}
          setOpen={handleCloseExtras}
          folio={Mapeo}
          Idd={Idd}
        />

      </React.Fragment>
    </>
  );
}

export default PanelDeControlProvee;
