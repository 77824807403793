import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import Home from './components/PaginaWeb/Pagina de inicio/Home';
import QuienesSomos from './components/PaginaWeb/Pagina de inicio/Quienes somos';
import Reservas from './components/PaginaWeb/Pagina de inicio/Reservas';
import Servicios from './components/PaginaWeb/Pagina de inicio/Servicios';
import Confirmacion from './components/PaginaWeb/Paginas Transbank/Confirmacion';
import Error from './components/PaginaWeb/Paginas Transbank/Error';
import Proceso from './components/PaginaWeb/Paginas Transbank/Proceso';
import Tours from './components/PaginaWeb/Pagina de inicio/Tours';
import ReactPixel from 'react-facebook-pixel';
import Page404 from './components/PaginaWeb/PageError';
import Verificacion from './components/PaginaWeb/Pagina de inicio/Verificacion';
import Contacto from './components/PaginaWeb/Pagina de inicio/Contacto';
import InformacionPasajeros from './components/PaginaWeb/Pagina de inicio/InformacionPasajeros';
import PersonalAcreditado from './components/PaginaWeb/Pagina de inicio/PersonalAcreditado';
import Qwerty from "./components/PaginaWeb/Pagina de inicio/Qwerty";
import PanelDeControlProvee from './components/PaginaWeb/Pagina de inicio/PanelDeControlProvee';
import { Login } from './components/PaginaWeb/Pagina de inicio/Login';
import { ProtectedRoute } from './context/ProtectedRoute';
import PanelDeControlProveeFinanzas from './components/PaginaWeb/Pagina de inicio/PanelDeControlProveeFinanzas';
import PagosFacturas from './components/PaginaWeb/Pagina de inicio/PagosFacturas';
import Conductor from './components/PaginaWeb/Pagina de inicio/Conductor';

function App() {
  const [datos, setDatos] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const options = {
  //     autoConfig: true,
  //     debug: false,
  //   };
  //   ReactPixel.init('1193397671640698', null, options);
  //   ReactPixel.pageView();
  // }, []);

  useEffect(() => {
    // Revisar si ya hay una sesión activa
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleData = (data, callback = () => {}) => {
    setDatos(data);
    callback(data);
  };

  // Este handleLogin recibirá true si el login es correcto
  const handleLogin = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem('isAuthenticated', status);

    // IMPORTANTE: aquí deberías guardar el "position" real
    // que viene de tu API, no forzar a "Conductor" siempre.
    // Ej: localStorage.setItem('position', positionDelUsuario);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('position');
  };

  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Home handleData={handleData} />} />
        <Route path="/Quienes-Somos" element={<QuienesSomos />} />
        <Route path="/Reservas" element={<Reservas datos={datos} handleData={handleData} />} />
        <Route path="/VerificacionPersonalAcreditado" element={<PersonalAcreditado datos={datos} handleData={handleData} />} />
        <Route path="/Servicios" element={<Servicios />} />
        <Route path="/Contacto" element={<Contacto />} />
        <Route path="/Tours" element={<Tours />} />
        <Route path="/Confirmacion" element={<Confirmacion />} />
        <Route path="/Verificacion" element={<Verificacion />} />
        <Route path="/InformacionPasajeros" element={<InformacionPasajeros />} />
        <Route path="/Error" element={<Error />} />
        <Route path="/Proceso" element={<Proceso />} />
        <Route path="/qwertyuioipñlkjjhgfdfsaazxccvbb" element={<Qwerty />} />
        <Route path="*" element={<Page404 />} />

        {/* Rutas protegidas para Proveedor: */}
        <Route 
          path="/PortalProveedor" 
          element={
            <ProtectedRoute roleRequired="Operador">
              <PanelDeControlProvee />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/PanelDeControlProveeFinanzas" 
          element={
            <ProtectedRoute roleRequired="Finanzas">
              <PanelDeControlProveeFinanzas />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/PagosFacturas" 
          element={
            <ProtectedRoute roleRequired="Finanzas">
              <PagosFacturas />
            </ProtectedRoute>
          } 
        />

        {/* Rutas protegidas para Conductor */}
        <Route 
          path="/Conductor" 
          element={
            <ProtectedRoute roleRequired="Conductor">
              <Conductor />
            </ProtectedRoute>
          } 
        />

        {/* Ruta de login */}
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
      </Routes>
    </Router>
  );
}

export default App;
